import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';

import { IReportVouchersModel } from 'api/models/reports';
import { headVouchersReportColumnsDefault, headVouchersReportSelectableColumns } from 'data';
import { IWithId } from 'const';
import {
    Box,
    TableRow,
    TableCell,
} from '@mui/material';
import VouchersReportTableCellContent from 'pages/Reports/Vouchers/VouchersReportTable/VouchersReportTableCellContent/VouchersReportTableCellContent';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const VouchersReportTableRow = ({
    activeColumns,
    row,
    isMobileView,
    handleRedirect
}: {
    readonly activeColumns: Array<IWithId>;
    readonly row: IReportVouchersModel;
    readonly isMobileView: boolean;
    readonly handleRedirect: (voucherId: number) => void;
}) => {
    const classes = useStyles();

    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <TableRow className={classes.row}
            hover
            onClick={() => handleRedirect(row.voucherId)}
            tabIndex={-1}
        >
            {!isMobileView
                ? headVouchersReportSelectableColumns.map((column, index) => {
                    const { id } = column;
                    const showColumn = column.permission ?? true;
                    const isColumnActive = activeColumns.some(activeColumn => activeColumn.id === id);
                    const className = clsx(
                        classes.cell,
                        headVouchersReportColumnsDefault.some(defaultColumn => defaultColumn.id === id) && classes.defaultCell,
                        classes[`${column.id}Cell`],
                    );

                    return showColumn && isColumnActive ? (
                        <TableCell className={className}
                            scope={index === 0 ? 'row' : null}
                            key={id}
                        >
                            <VouchersReportTableCellContent
                                id={id}
                                row={row}
                            />
                        </TableCell>
                    ): null;
                }): (
                    <>
                        <TableCell className={clsx(classes.cell, classes.firstCell)}>
                            <span className={clsx(classes.boxTitle, classes.voucher)}>
                                {`${translations['emp-reports-vouchers-voucher-excel-title']}`}
                            </span>
                            <VouchersReportTableCellContent
                                id={'voucherId'}
                                row={row}
                            />
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <Box className={clsx(classes.boxWithTitle)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-vouchers-issue-date']}
                                </span>
                                <VouchersReportTableCellContent
                                    id={'issueDate'}
                                    row={row}
                                />
                            </Box>
                            <Box className={clsx(classes.boxWithTitle, classes.boxEnd)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-vouchers-cash-out-date']}
                                </span>
                                <VouchersReportTableCellContent
                                    id={'cashoutDate'}
                                    row={row}
                                />
                            </Box>
                        </TableCell>
                        <TableCell className={clsx(classes.cell, classes.lastCell)}>
                            <Box className={clsx(classes.boxWithTitle)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-vouchers-cashed-out-by']}
                                </span>
                                <VouchersReportTableCellContent
                                    id={'cashoutByEntityName'}
                                    row={row}
                                />
                            </Box>
                            <Box className={clsx(classes.boxWithTitle, classes.boxEnd)}>
                                <span className={classes.boxTitle}>
                                    {translations['emp-reports-vouchers-voucher-cost']}
                                </span>
                                <VouchersReportTableCellContent
                                    id={'cost'}
                                    row={row}
                                />
                            </Box>
                        </TableCell>
                    </>
                )
            }
        </TableRow>
    );
};

export default VouchersReportTableRow;
